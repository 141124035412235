.accreditations {
    padding: 5px;
    //background-color: white;
    margin-top: 10px;
    //border: 2px solid #999999;
}

.accreditations img {
    border-radius: 5px;   
}

.footer-logo {
 padding:2px;
 border:1px;
 
}

.home-slider-container {
text-align: center;
padding: 0px 10px 10px 10px;
background-color: @brand-primary;
color: white;
margin: 0;

span {
    font-size: 25px;
    margin: 5px;
}
}

.home-slider-container h3 {
    color: white;
    padding-top: 15px;
    margin-top: 0;
}
a:hover {
    text-decoration: none;
}

.nav a {
  font-family: 'Montserrat', sans-serif;
  //text-transform: uppercase;
  
  -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    transition: 0.5s all;
}

nav {
    box-shadow: 0 0 15px -5px black;
    z-index: 999;
}

.active {
    color: darken(@brand-primary, 10%) !important;   
}

.head-image {
  background-size: cover;
  background-position: center;
  position: relative;
  
  .banner-accred {
    img {
        position: absolute;
        bottom: 10px;
        right: 10px;
    }
}
}

.head-image-repeat {
    
}

.title {
  background-color: fade(@navbar-etc, 65%);
  padding: 20px;
  margin: 40px 0;
  color: @text-color;
    border-radius: 4px;
  h1 {
    margin-top: 0;
  }
}

@button-color: @brand-primary;

.btn-square {
    border: 2px solid @button-color;
    border-radius: 2px;
    background: transparent;
    color: @button-color;
    
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    
    float: right;
}

.btn {
    font-family: 'Montserrat', sans-serif;
    
    -webkit-transition: 0.1s all;
    -moz-transition: 0.1s all;
    -o-transition: 0.1s all;
    transition: 0.1s all;
}

.btn-square:hover, .btn-square:active {
    background-color: @button-color;
    color: fade(@navbar-etc, 80%);
}

hr {
    border: 1px solid @brand-primary;
    margin: 10px 0;
}

.index-tiles {
   text-align: left;
   background-color: @navbar-etc;
   width: 100%;
   position: relative;
   height: auto;
   padding: 20px 0;

   .tile {
      padding: 40px 20px;
      display: block;
   }
   
   a.tile {
        color: @text-color;   
        text-decoration: none;
        
        -webkit-transition: 0.4s color;
        -moz-transition: 0.4s color;
        -o-transition: 0.4s color;
        transition: 0.4s color;
   }
   
   a.tile:hover, a.tile:active {
       text-decoration: none;
       color: @brand-primary;//lighten(@text-color, 25%);
   }
   
   .tile-img {
      background-color: @brand-primary;
      text-align: center;
      float: left;
      margin-right: 20px;
      height: 100px;
      width: 100px;
      border-radius: 50px;
      overflow: hidden;

      span {
         margin-top: 25px;
         color: @navbar-etc;
         font-size: 50px;
      }



   }

   h5 {
      font-size: 25px;
   }
}

@media screen and (max-width: 1200px) {
    .index-tiles {

        a.tile {
            text-align: center !important;   
            padding: 10px 20px;
            
        }
        
       .tile-img {
          text-align: center;
          margin: 0 auto;
          float: none;
       
          
          span {
             margin-top: 25px;
          }
       }
    
       h5 {
          clear: both;
       }
    }
}

// Adjust footer on mobile
@media screen and (max-width: @screen-sm) {
    .footer {
         .text-center {
             text-align: left;
         }
         .text-right {
             text-align: left;
         }
    }
}

.banner-text {
    background-color: @brand-primary;
    color: @navbar-etc;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px ;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    
    .container {
        padding: 10px;   
    }
    
    a {
        color: darken(@brand-primary, 15%);   
    }
}

.banner-text1 {
  
    color: @brand-primary;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px ;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    
    .container {
        padding: 10px;   
    }
    
    a {
        color: darken(@brand-primary, 15%);   
    }
}


.main-content {
    padding: 30px 0;
}

.footer {
    background-color: @brand-primary;   
    padding: 15px 0;
    
    box-shadow:inset 0 0 15px -5px black;
    //border-top: solid 1px darken(@brand-primary, 5%);
}

.footer a {
    color: darken(@brand-primary, 20%);
}

.copyright {
	padding-top: 15px;
}

.social img {
    margin: 5px;
}

.owl-pagination {
    text-align: left;
}

 
 .owl-controls {
     .container
 }
 
 .contact #message {
     display: none;
 }
 
 .hidden-verif {
    position: absolute;
    top: -20px;
    opacity: 0;
    cursor: none;
    height: 0;
 }
 
 .gallery-thumb {
     border: solid 2px @navbar-etc;
     cursor:pointer;
 }
 
.gallery-thumb:hover {
     opacity: 0.7;
 }
 
 blockquote strong {
    .h4;
 }
 
 .areas-covered {
     display: inline-block;
 }
 
 .areas-covered li {
    display: inline-block;
    float: left;
    padding: 15px;
    margin: 5px;
    .label;
    .label-primary;
    .h4;
    color: white;
 }
 
 .tradelogos {
    text-align: center;   
 }
 
 .tradelogos img {
    margin: 15px;   
 }
 
 .gallerynav {
    margin: 20px 0;   
 }
 
 .gallerynav a {
    margin-right: 10px;
 }
 
 // Overide for gallery banners
 .text-left {
    text-align: left;   
 }